const config = {
  envName: "next",
  firebaseConfig: {
    apiKey: "AIzaSyD0gXx4ZOYSPKDGrPjsvZmlzIymBAdDM5M",
    authDomain: "tekutekulife-campaign-next.firebaseapp.com",
    projectId: "tekutekulife-campaign-next",
    storageBucket: "tekutekulife-campaign-next.appspot.com",
    messagingSenderId: "842154449557",
    appId: "1:842154449557:web:f77df30a4249fee714599c",
    measurementId: "G-ZP9N66DGYP"
  }
};

export default config;
