import React from "react";
import styled from "styled-components";
import { color, fontSize } from "../styles/common";

export const Message = styled.div`
  ${fontSize.large};
  text-align: center;
  font-weight: bold;
  padding: 32px 0;
`;

export const SubMessage = styled.div`
  ${fontSize.small};
  text-align: center;
  line-height: 1.8rem;
`;

export const Duration = styled(({ className, term, children }: React.PropsWithChildren<{ className?: string; term: string }>) => {
  return (
    <div className={className}>
      <div>{term}</div>
      <div>{children}</div>
    </div>
  );
})`
  display: flex;
  flex-flow: row nowrap;
  color: ${color.durationGreen};
  text-align: center;
  ${fontSize.xsmall}
  & > div:nth-of-type(1) {
    &:after {
      content: ":";
      margin-right: 4px;
    }
  }
  & > div:nth-of-type(2) {
    font-weight: bold;
  }
`;
