import React, { ReactElement } from "react";
import { Link, LinkProps } from "@material-ui/core";

const ExternalLink = ({ children, ...props }: LinkProps): ReactElement => {
  return (
    <Link {...props} rel="noreferrer" target="_blank">
      {children}
    </Link>
  );
};

export default ExternalLink;
