import React, { ReactElement } from "react";
import styled from "styled-components";
import config from "../../config";
import { color, fontSize } from "../styles/common";
import ExternalLink from "../atoms/ExternalLink";

const Root = styled.footer`
  padding: 16px 0 8px 0;
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  background-color: ${color.footerGray};
`;
const InquiryLink = styled(ExternalLink)`
  color: ${color.black};
  ${fontSize.small};
  margin: 0 auto;
`;
const CopyLight = styled.div`
  padding-top: 16px;
  color: ${color.textSecondary};
  ${fontSize.small};
  margin: 0 auto;
`;
interface Props {
  className?: string;
}
const Footer = ({ className }: Props): ReactElement => {
  let envName = "";
  if (config.envName !== "prod") {
    envName = `(${config.envName})`;
  }
  return (
    <Root className={className}>
      <InquiryLink href={config.inquiryUrl}>お問い合わせ</InquiryLink>
      <CopyLight>© テクテクライフ{envName}</CopyLight>
    </Root>
  );
};

export default Footer;
