import { CampaignInfo, LotteryResult } from "../../app-types";

const gifts: LotteryResult["lottery"]["gifts"] = [
  {
    type: "image",
    content:
      "https://prod.tekutekulife.com/resource/1/misc/2021EVA_%E3%83%81%E3%82%A7%E3%83%83%E3%82%AF%E3%82%A4%E3%83%B3%E6%BC%94%E5%87%BA_%E3%82%AB%E3%83%B2%E3%83%AB_1_gOrMykNPADjoQqdWryqelopZ712GwLxd.jpg"
  },
  {
    type: "image",
    content:
      "https://prod.tekutekulife.com/resource/1/misc/2021EVA_%E3%83%81%E3%82%A7%E3%83%83%E3%82%AF%E3%82%A4%E3%83%B3%E6%BC%94%E5%87%BA_%E3%82%AB%E3%83%B2%E3%83%AB_2_w3W8kEKRO9NlqeZ755V8ElJAxymqda75.jpg"
  },
  {
    type: "image",
    content:
      "https://prod.tekutekulife.com/resource/1/misc/2021EVA_%E3%83%81%E3%82%A7%E3%83%83%E3%82%AF%E3%82%A4%E3%83%B3%E6%BC%94%E5%87%BA_%E3%82%AB%E3%83%B2%E3%83%AB_3_yvOenam8WwApakyjPPj69Ybz7NEl6rL4.jpg"
  },
  {
    type: "image",
    content:
      "https://prod.tekutekulife.com/resource/1/misc/2021EVA_%E3%83%81%E3%82%A7%E3%83%83%E3%82%AF%E3%82%A4%E3%83%B3%E6%BC%94%E5%87%BA_%E3%82%AB%E3%83%B2%E3%83%AB_4_6JmkdRnjo8q4LNobMkByl3bwxzG2eQYL.jpg"
  },
  {
    type: "image",
    content:
      "https://prod.tekutekulife.com/resource/1/misc/2021EVA_%E3%83%81%E3%82%A7%E3%83%83%E3%82%AF%E3%82%A4%E3%83%B3%E6%BC%94%E5%87%BA_%E3%82%AB%E3%83%B2%E3%83%AB_5_palGVX1KZxWbaNAlPyyjRoN7yEO920Bn.jpg"
  }
];
const winningLottery = {
  id: "dummy-lottery-id-100",
  lotteryAt: 1626966001, // 2021-07-22T15:00:01Z
  winning: {
    imageUrl: "https://prod.tekutekulife.com/resource/1/misc/2021IW%E9%96%8B%E7%99%BA_%E3%83%80%E3%83%9F%E3%83%BC%E6%99%AF%E5%93%81%E7%94%BB%E5%83%8F_wvrQK1oglj0q5Rx4BW1K724yW5nB6kEd.png",
    formUrl: "https://tekutekulife.com",
    prizeId: "200000010"
  },
  gifts
};
const loseLottery = {
  id: "dummy-lottery-id-100",
  lotteryAt: 1626966001, // 2021-07-22T15:00:01Z
  winning: null,
  gifts
};
const campaignBase = {
  campaignId: "170000000",
  playerId: "dummy-player-id",
  title: "ダミーキャンペーン",
  description: "ダミーキャンペーンの説明文です",
  headerImageUrl:
    "https://prod.tekutekulife.com/resource/1/misc/2021IW%E9%96%8B%E7%99%BA_%E3%83%80%E3%83%9F%E3%83%BC%E3%83%98%E3%83%83%E3%83%80%E3%83%BC%E7%94%BB%E5%83%8F_xXB74dWJGegpr0ynKVYWKlRwaq6nNb8y.png",
  topImageUrl: "https://prod.tekutekulife.com/resource/1/misc/2021IW%E9%96%8B%E7%99%BA_%E3%83%80%E3%83%9F%E3%83%BC%E3%83%88%E3%83%83%E3%83%97%E7%94%BB%E5%83%8F_nLDQEOjzBGKPzr0nl3yq5P1WXo5YqJRb.png",
  terms: "### ①hogehoge\nほげほげなほげほげ\n### ②fugafuga\nふがふがなふがふが",
  details: `### ほげほげ\nほげほげなほげほげ\n### ふがふが\nふがふがなふがふが\n### 長い文章\n${Array.from(Array(200))
    .map(() => "スクロール")
    .join("\n")}`,
  start: 1625151600, // 2021-06-31T15:00:00Z
  end: 1914418799 // 2030-08-31T14:59:59Z
};

export const CAMPAIGN_NONE: CampaignInfo = {
  ...campaignBase,
  lottery: null
};
export const CAMPAIGN_WINNING: LotteryResult = {
  ...campaignBase,
  lottery: winningLottery
};
export const CAMPAIGN_LOSE: LotteryResult = {
  ...campaignBase,
  lottery: loseLottery
};
