let envConfig = {};
// create-react-appがDefinePluginを使っているのでこのようにif文を書くことで、
// ビルド時に余計な環境のconfigがソースコードに含まれてしまうのを防ぐことができる
if (process.env.REACT_APP_TK2L_ENV === "debug") {
  envConfig = require("./debug").default;
} else if (process.env.REACT_APP_TK2L_ENV === "dev") {
  envConfig = require("./dev").default;
} else if (process.env.REACT_APP_TK2L_ENV === "next") {
  envConfig = require("./next").default;
} else if (process.env.REACT_APP_TK2L_ENV === "prod") {
  envConfig = require("./prod").default;
}

interface Config {
  envName: string;
  officialTwitterUrl: string;
  inquiryUrl: string;
  functionsRegion: string;
  firebaseConfig: Object;
  functionsEmulator: {
    host: string;
    port: number;
  } | null;
}
const defaultConfig = {
  envName: "debug",
  officialTwitterUrl: "https://twitter.com/teku_4",
  inquiryUrl: "https://help.tekutekulife.com/hc/ja/requests/new",
  functionsRegion: "asia-northeast1",
  firebaseConfig: {},
  functionsEmulator: null
};
const config = {
  ...defaultConfig,
  ...envConfig
};
export default config as Config;
