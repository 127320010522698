import firebase from "firebase/app";
import * as modules from "./modules";
import { LotteryResult, CampaignInfo } from "../app-types";
import * as constants from "../constants";

let serviceInstance: FirebaseService;
export class FirebaseService {
  private functionsClient: firebase.functions.Functions;
  private analyticsClient: firebase.analytics.Analytics;
  private constructor(functions: firebase.functions.Functions, analytics: firebase.analytics.Analytics) {
    this.functionsClient = functions;
    this.analyticsClient = analytics;
  }
  static createService() {
    if (serviceInstance != null) return serviceInstance;
    const functions = modules.FirebaseModule.getFunctionsClient();
    const analytics = modules.FirebaseModule.getAnalyticsClient();
    serviceInstance = new FirebaseService(functions, analytics);
    return serviceInstance;
  }
  async checkBasicAuth(auth: { id: string; password: string }): Promise<void> {
    const checkBasicAuth = this.functionsClient.httpsCallable("checkBasicAuth");
    await checkBasicAuth(auth);
  }
  async getCampaignResult(token: string, campaignName: string): Promise<{ result: CampaignInfo; error?: string }> {
    // キャンペーンID不正、トークン不正(整合性チェック、有効期限切れ)はすべてエラーを返す
    const campaignResult = this.functionsClient.httpsCallable("campaignResult");
    const result = await campaignResult({ token, campaignName });
    return result.data;
  }
  async lotteryCampaign(token: string, campaignName: string): Promise<{ result: LotteryResult; error?: string }> {
    // キャンペーンID不正、トークン不正(整合性チェック、有効期限切れ)はすべてエラーを返す
    const lotteryCampaign = this.functionsClient.httpsCallable("lotteryCampaign");
    const result = await lotteryCampaign({ token, campaignName });
    return result.data;
  }
  logScreenView(screenName: "top" | "win" | "lose") {
    this.analyticsClient.logEvent(firebase.analytics.EventName.SCREEN_VIEW, {
      app_name: "tekutekulife-campaign",
      firebase_screen: screenName,
      firebase_screen_class: screenName
    });
  }

  logDoLotteryEvent(playerId: string, campaignId: string, lotteryId: string, prizeId: string | null, lotteryResult: "win" | "lose") {
    const eventParams: { [key: string]: string } = {
      [constants.GA_EVENT_PARAMS_PLAYER_ID]: playerId,
      [constants.GA_EVENT_PARAMS_CAMPAIGN_ID]: campaignId,
      [constants.GA_EVENT_PARAMS_LOTTERY_ID]: lotteryId,
      [constants.GA_EVENT_PARAMS_LOTTERY_RESULT]: lotteryResult
    };
    if (prizeId != null) {
      eventParams[constants.GA_EVENT_PARAMS_PRIZE_ID] = prizeId;
    }
    this.analyticsClient.logEvent(constants.GA_EVENT_NAME_TTL_DO_LOTTERY, eventParams);
  }
}
