import styled from "styled-components";

interface Props {
  src: string;
  rate: number;
}
const ResponsiveImage = styled.div<Props>`
  &:before {
    content: "";
    display: block;
    padding-top: ${(props) => `${props.rate}%`};
  }
  width: 100%;
  background-image: ${(props) => `url(${props.src})`};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
`;

export default ResponsiveImage;
