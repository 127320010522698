import { range } from "./arr";

export function padZero(num: number, digit = 2): string {
  const numStr = `${num}`;
  const diffLength = digit - `${num}`.length;
  if (diffLength > 0) {
    return (
      range(1, diffLength)
        .map(() => "0")
        .join("") + numStr
    );
  }
  return numStr;
}
