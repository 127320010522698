import React, { useState, ReactElement, useEffect } from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, TextField } from "@material-ui/core";
import { useLocalStorage } from "react-use";
import { FirebaseService } from "../services";

interface IdAndPass {
  id: string;
  password: string;
}
// devやnext版で一般人にページが見られないよう、functionsと連携してBasic認証っぽいことをする
interface Props {}
export const withDevProtect =
  <T extends {}>(WrappedComponent: React.ComponentType<T>): React.VFC<T> =>
  (props: T & Props): ReactElement<T> => {
    if (process.env.REACT_APP_TK2L_ENV === "dev" || process.env.REACT_APP_TK2L_ENV === "next") {
      const ID_AND_PASS_LOCAL_STORAGE_KEY = "dev-protect-idpass";
      const [loading, setLoading] = React.useState<boolean>(true);
      const [isProtected, setProtected] = useState<boolean>(true);
      const [idAndPassInStorage, setIdAndPassInStorage, removeIdAndPassInStorage] = useLocalStorage<IdAndPass>(ID_AND_PASS_LOCAL_STORAGE_KEY, undefined);
      const [idAndPass, setIdAndPass] = useState<IdAndPass | null>(idAndPassInStorage || null);

      const [id, setId] = React.useState<string>("");
      const [password, setPassword] = React.useState<string>("");
      useEffect(() => {
        if (idAndPass !== null) {
          setLoading(true);
          const firebaseService = FirebaseService.createService();
          firebaseService
            .checkBasicAuth(idAndPass)
            .then(() => {
              setLoading(false);
              setProtected(false);
              setIdAndPassInStorage(idAndPass);
            })
            .catch(() => {
              setLoading(false);
              setProtected(true);
              removeIdAndPassInStorage();
            });
        } else {
          setLoading(false);
        }
      }, [idAndPass]);
      const onCloseIdAndPassModal = () => {
        if (id && password) {
          setIdAndPass({ id, password });
        } else {
          setIdAndPass(null);
        }
      };
      const onChangeId = (event: React.ChangeEvent<HTMLInputElement>) => {
        setId(event.target.value || "");
      };
      const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value || "");
      };
      if (loading) {
        return (
          <div style={{ display: "flex", flexFlow: "column nowrap" }}>
            <CircularProgress style={{ margin: "72px auto 0" }} />
          </div>
        );
      }
      if (isProtected) {
        return (
          <Dialog open={true} onClose={onCloseIdAndPassModal} disableBackdropClick disableEscapeKeyDown>
            <DialogContent>
              <DialogContentText>IDとパスワードを入力して下さい</DialogContentText>
              <TextField autoFocus margin="dense" label="ID" fullWidth onChange={onChangeId} />
              <TextField autoFocus margin="dense" label="パスワード" type="password" onChange={onChangePassword} fullWidth />
            </DialogContent>
            <DialogActions>
              <Button onClick={onCloseIdAndPassModal} color="primary">
                送信
              </Button>
            </DialogActions>
          </Dialog>
        );
      }

      return <WrappedComponent {...props} />;
    } else {
      // 本番とローカル環境は常にこっちの分岐
      return <WrappedComponent {...props} />;
    }
  };
