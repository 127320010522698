import React from "react";
import { Helmet } from "react-helmet-async";
import { useParams, useLocation } from "react-router-dom";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";
import { InvalidExpirationCampaignInfo, CampaignInfo, LotteryResult } from "../app-types";
import * as constants from "../constants";
import * as api from "../utils/api";
import { logScreenView, logDoLotteryEvent } from "../utils/ga";
import ResponsiveImage from "../components/atoms/ResponsiveImage";
import LotteryResultPage from "../components/organisms/LotteryResult";
import CampaignTop from "../components/organisms/CampaignTop";
import ErrorPage from "./ErrorPage";
import OutOfTermPage from "./OutOfTermPage";

function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

function hasLotteryResult(campaignInfo: CampaignInfo): campaignInfo is LotteryResult {
  return campaignInfo.lottery !== null;
}

enum LoadingState {
  Loading,
  Loaded
}
interface Params {
  campaignName: string;
}
interface Props {
  className?: string;
}

const Root = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 0 0 16px 0;
`;
const CircularProgressStyled = styled(CircularProgress)`
  margin: 72px auto 0;
`;
const Header = styled(ResponsiveImage)`
  width: 100%;
  margin: 0 auto;
`;

const Campaign = ({ className }: Props) => {
  const { campaignName } = useParams<Params>();
  const query = useQuery();
  const token = query.get("token");

  if (token === null) {
    return <ErrorPage />;
  }
  let apiUtil = api;
  if (process.env.REACT_APP_TK2L_ENV !== "prod" && query.get("mockMode") === "1") {
    // このコードは本番では読み込まれない
    apiUtil = require("../utils/api-mock");
  }
  const [invalidCampaignInfo, setInvalidCampaignInfo] = React.useState<InvalidExpirationCampaignInfo | null>(null);
  const [campaignInfo, setCampaignInfo] = React.useState<CampaignInfo | LotteryResult | null>(null);
  const [loadingState, setLoadingState] = React.useState<LoadingState>(LoadingState.Loading);
  const [gaScreen, setGaScreen] = React.useState<"top" | "win" | "lose" | null>(null);
  const [gaDoLottery, setGaDoLottery] = React.useState<{ playerId: string; campaignId: string; lotteryId: string; prizeId: string | null; lotteryResult: "win" | "lose" } | null>(null);
  const [hasError, setError] = React.useState<Error | null>(null);

  React.useEffect(() => {
    if (gaScreen !== null) {
      // ReactHelmetによるタイトル(page_title)の更新よりも前にイベント送信がされてしまうため、
      // 最初のページロードの時、GAに適切なページタイトルが記録されない問題がある
      // 使ってるのはreact-helmet-asyncだが同じ現象だろう: https://github.com/nfl/react-helmet/issues/189
      // setTimeoutを挟むことで、Helmetへの反映を待った上でgaイベントを送信する
      setTimeout(() => {
        logScreenView(gaScreen);
      }, 1000);
    }
  }, [gaScreen]);
  React.useEffect(() => {
    if (gaDoLottery !== null) {
      // 最初のページロードではpage_titleが遅延する問題があるが、抽選実行時はタイトルが反映済みなので、setTimeoutしない
      logDoLotteryEvent(gaDoLottery.playerId, gaDoLottery.campaignId, gaDoLottery.lotteryId, gaDoLottery.prizeId, gaDoLottery.lotteryResult);
    }
  }, [gaDoLottery]);

  React.useEffect(() => {
    apiUtil
      .getCampaignInfoByToken(token, campaignName)
      .then((response) => {
        if (response.error == null) {
          // キャンペーン情報取得
          const info = response.result;
          const imgTag = new Image();
          imgTag.onload = () => {
            setLoadingState(LoadingState.Loaded);
          };
          imgTag.src = info.headerImageUrl;
          setCampaignInfo(info);
          setGaScreen(!hasLotteryResult(info) ? "top" : info.lottery.winning !== null ? "win" : "lose");
        } else if (response.error === constants.FunctionError.InvalidExpiration) {
          // 期間外の時のみレスポンスからキャンペーン情報を取得する
          setInvalidCampaignInfo(response.result as InvalidExpirationCampaignInfo);
        } else {
          // それ以外はエラーとしてスロー
          throw new Error(response.error);
        }
      })
      .catch((err) => {
        console.error("Error on getCampaignInfoByToken", err);
        setError(err);
      });
  }, [token, campaignName]);

  if (hasError) {
    return <ErrorPage />;
  }
  // キャンペーン期間外
  if (invalidCampaignInfo) {
    return <OutOfTermPage campaignInfo={invalidCampaignInfo} />;
  }

  if (loadingState === LoadingState.Loading || campaignInfo === null) {
    return (
      <Root className={className}>
        {/* note: 抽選時、ロードインディケーター表示中に一瞬タイトルがもとに戻ってしまうためちゃんとHelmetを入れておく */}
        {/* タイトルをちゃんとしておく意図としては、抽選時のlogDoLotteryEventでGAのイベントを送信する際、戻ったタイトルでログが記録されてしまうため */}
        {campaignInfo !== null ? (
          <Helmet>
            <title>{campaignInfo.title} | テクテクライフ</title>
            <meta name="description" content={campaignInfo.description} />
          </Helmet>
        ) : null}
        <CircularProgressStyled />
      </Root>
    );
  }

  const onClickEnter = () => {
    setLoadingState(LoadingState.Loading);
    apiUtil
      .postLotteryCampaignByToken(token, campaignName)
      .then((response) => {
        if (response.error == null) {
          // キャンペーン情報取得
          const info = response.result;
          setCampaignInfo(info);
          setGaScreen(info.lottery.winning !== null ? "win" : "lose");
          setGaDoLottery({
            playerId: info.playerId,
            campaignId: info.campaignId,
            lotteryId: info.lottery.id,
            prizeId: info.lottery.winning !== null ? info.lottery.winning.prizeId : null,
            lotteryResult: info.lottery.winning !== null ? "win" : "lose"
          });
        } else if (response.error === constants.FunctionError.InvalidExpiration) {
          // 期間外の時のみレスポンスからキャンペーン情報を取得する
          setInvalidCampaignInfo(response.result as InvalidExpirationCampaignInfo);
        } else {
          // それ以外はエラーとしてスロー
          throw new Error(response.error);
        }
        setLoadingState(LoadingState.Loaded);
      })
      .catch((err) => {
        console.error("Error on postLotteryCampaignByToken", err);
        setError(err);
      });
  };

  return (
    <Root className={className}>
      <Helmet>
        <title>{campaignInfo.title} | テクテクライフ</title>
        <meta name="description" content={campaignInfo.description} />
      </Helmet>
      <Header rate={(160 / 360) * 100} src={campaignInfo.headerImageUrl} />
      {hasLotteryResult(campaignInfo) ? <LotteryResultPage lotteryResult={campaignInfo} /> : <CampaignTop onClickEnter={onClickEnter} campaignInfo={campaignInfo} />}
    </Root>
  );
};

export default Campaign;
