import { padZero } from "./num";

export function dateToUnixTime(date: Date) {
  return Math.floor(date.getTime() / 1000);
}
export function unixTimeToDate(sec: number) {
  return new Date(sec * 1000);
}
export function formatUnixTimeToJaDateString(ts: number): string {
  const date = unixTimeToDate(ts);
  return `${padZero(date.getFullYear())}年${padZero(date.getMonth() + 1)}月${padZero(date.getDate())}日`;
}
export function formatUnixTimeToJaDateTimeString(ts: number): string {
  const date = unixTimeToDate(ts);
  return `${padZero(date.getFullYear())}年${padZero(date.getMonth() + 1)}月${padZero(date.getDate())}日 ${padZero(date.getHours())}時${padZero(date.getMinutes())}分`;
}
