import { css } from "styled-components";

/* キャンペーンサイトにおける、ヘッダーなどの最大サイズ */
export const contentWidth = 720;
export const breakpoints = {
  xs: 0,
  /* phone: 0-599 */
  sm: 600,
  /* tablet: 600-1279 */
  md: 960,
  /* tablet: 600-1279 */
  lg: 1280,
  /* laptop,desktop: 1280- */
  xl: 1920
};

export const media = {
  /* phone: 0-599 */
  phone: `@media (max-width: ${breakpoints.sm}px)`,
  /* tablet: 600-1279 */
  tablet: `@media (max-width: ${breakpoints.lg - 1}px)`,
  /* laptop,desktop: 1280- */
  desktop: `@media (min-width: ${breakpoints.lg}px)`
};

export const color = {
  black: "#000000",
  white: "#ffffff",
  // Material-UIのデフォルトを拝借
  // https://material-ui.com/customization/default-theme/#default-theme
  textPrimary: "rgba(0, 0, 0, 0.87)",
  textSecondary: "rgba(0, 0, 0, 0.54)",
  // https://material.io/resources/color/#!/?view.left=0&view.right=1&primary.color=1962eb&secondary.color=FF8908&secondary.text.color=ffffff
  blue: "#1962eb",
  darkBlue: "#0039b8",
  lightBlue: "#6b8fff",
  orange: "#ff8908",
  darkOrange: "#c55a00",
  lightOrange: "#ffba47",

  // Material-UIのデフォルトを拝借
  // https://material-ui.com/customization/default-theme/#default-theme
  lightGray: "#e0e0e0",
  gray: "#9e9e9e",
  darkGray: "#616161",

  // 以下は独自設定
  footerGray: "#f3f3f3",
  durationGreen: "#019592",
  giftListBgGreen: "#dff2f4"
};

export const fontSize = {
  base: 14, // 14px
  xsmall: css`
    font-size: 0.857rem;
  `,
  small: css`
    font-size: 1rem;
  `,
  medium: css`
    font-size: 1.2rem;
  `,
  large: css`
    font-size: 1.4rem;
  `,
  h4: css`
    font-size: 1.6rem;
  `
};

// Material-UIのデフォルトを拝借
export const fontFamily = [
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"'
].join(",");
