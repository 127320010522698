import React, { ReactElement } from "react";
import styled from "styled-components";
import { InvalidExpirationCampaignInfo } from "../app-types";
import { Message } from "../components/atoms/Messages";
import ResponsiveImage from "../components/atoms/ResponsiveImage";
import CampaignMetadata from "../components/molecules/CampaignMetadata";

const Root = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
`;

const Logo = styled(ResponsiveImage)`
  margin: 72px auto;
  max-width: 166px;
  width: 40%;
`;

interface Props {
  className?: string;
  campaignInfo: InvalidExpirationCampaignInfo;
}

const OutOfTermPage = ({ className, campaignInfo }: Props): ReactElement => {
  return (
    <Root className={className}>
      <Logo rate={(173 / 333) * 100} src={process.env.PUBLIC_URL + "/logo-333x173.png"} />
      <Message>キャンペーンは終了しました</Message>
      <CampaignMetadata className={className} campaignTitle={campaignInfo.title} lotteryAt={campaignInfo.lotteryAt} lotteryId={campaignInfo.lotteryId} />
    </Root>
  );
};

export default OutOfTermPage;
