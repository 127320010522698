import React from "react";
import ReactMarkdown from "react-markdown";
import { Button, Checkbox, CheckboxProps, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from "@material-ui/core";
import styled from "styled-components";
import { CampaignInfo } from "../../app-types";
import PresentBoxAnimation from "../atoms/PresentBoxAnimation";
import ResponsiveImage from "../atoms/ResponsiveImage";

interface Props {
  campaignInfo: CampaignInfo;
  onClickEnter: React.MouseEventHandler<HTMLButtonElement>;
}

const TopImage = styled(ResponsiveImage)`
  margin: 0 auto;
`;
const OutlineButtonStyled = styled(Button)`
  margin: 16px auto 0;
  min-width: 160px;
`;
const AggreementLabel = styled(FormControlLabel)`
  margin: 16px auto 0;
`;
const EnterAnimationButton = styled(Button)`
  margin: 16px auto;
  min-width: 85%;
  font-weight: bold;
  font-size: 1.2rem;
`;
const TextScrollDialog = styled(Dialog)`
  & .paper {
    height: 90%;
  }
`;
const PresentBoxAnimationDialogContent = styled(DialogContent)`
  display: flex;
  flex-flow: column nowrap;
  padding: 8px;
`;
const PresentBoxAnimationStyled = styled(PresentBoxAnimation)`
  margin: 0 auto;
`;
const EnterButton = styled(Button)`
  width: 80%;
  margin: 16px auto;
  font-weight: bold;
  font-size: 1.2rem;
`;
const CampaignTop = ({ campaignInfo, onClickEnter }: Props) => {
  const [enterAnimationButtonEnabled, setEnterAnimationButtonEnabled] = React.useState(false);
  const [showTermsModal, setShowTermsModal] = React.useState(false);
  const [showDetailsModal, setShowDetailsModal] = React.useState(false);
  const [showAnimationModal, setShowAnimationModal] = React.useState(false);
  const [playAnimation, setPlayAnimation] = React.useState(false);
  // 利用規約ボタンが押されたら、利用規約のモーダルを開く
  const onClickTermsButton = () => {
    setShowTermsModal(true);
  };
  // キャンペーン概要ボタンが押されたら、キャンペーン概要のモーダルを開く
  const onClickDetailsButton = () => {
    setShowDetailsModal(true);
  };
  // 利用規約同意のチェックボックスと応募ボタンの状態を連動させる
  const onChangeAgreement: CheckboxProps["onChange"] = (_, checked) => {
    setEnterAnimationButtonEnabled(checked);
  };
  // 利用規約のモーダルを閉じる(閉じるボタン、バックドロップ)
  const onCloseTermsModal = () => {
    setShowTermsModal(false);
  };
  // キャンペーン概要のモーダルを閉じる(閉じるボタン、バックドロップ)
  const onCloseDetailsModal = () => {
    setShowDetailsModal(false);
  };
  // 応募するボタンが押されたら、アニメーションモーダルを開く
  const onClickEnterAnimationButton = () => {
    setShowAnimationModal(true);
  };
  // アニメーションモーダルを閉じる際に、抽選を実行する(抽選に進むボタン)
  // アニメーションの再生状態を元に戻す(遷移的には不可逆だが、念の為)
  const onCloseAnimationModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    setShowAnimationModal(false);
    setPlayAnimation(false);
    onClickEnter(e);
  };
  // アニメーションモーダルが開ききったら、プレゼントボックスアニメーションの再生を開始する
  const onEnteredAnimationModal = () => {
    setPlayAnimation(true);
  };
  return (
    <>
      <TopImage rate={(270 / 360) * 100} src={campaignInfo.topImageUrl} />

      <OutlineButtonStyled variant="outlined" color="primary" onClick={onClickDetailsButton}>
        キャンペーン概要
      </OutlineButtonStyled>
      <OutlineButtonStyled variant="outlined" color="primary" onClick={onClickTermsButton}>
        利用規約
      </OutlineButtonStyled>

      <AggreementLabel control={<Checkbox color="primary" onChange={onChangeAgreement} size="medium" name="同意する" />} label="利用規約に同意して応募する" />
      <EnterAnimationButton onClick={onClickEnterAnimationButton} disabled={!enterAnimationButtonEnabled} size="large" variant="contained" color="primary">
        応募する
      </EnterAnimationButton>
      {/* 利用規約のモーダル */}
      <TextScrollDialog classes={{ paper: "paper" }} open={showTermsModal} onClose={onCloseTermsModal} scroll="paper" fullWidth={true}>
        <DialogTitle>利用規約</DialogTitle>
        <DialogContent dividers={true}>
          <ReactMarkdown>{campaignInfo.terms}</ReactMarkdown>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseTermsModal} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </TextScrollDialog>
      {/* キャンペーン概要のモーダル */}
      <TextScrollDialog classes={{ paper: "paper" }} open={showDetailsModal} onClose={onCloseDetailsModal} scroll="paper" fullWidth={true}>
        <DialogTitle>キャンペーン概要</DialogTitle>
        <DialogContent dividers={true}>
          <ReactMarkdown>{campaignInfo.details}</ReactMarkdown>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseDetailsModal} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </TextScrollDialog>
      {/* 抽選に進むときのモーダル */}
      {/* 中でアニメーション用の画像をプリロードしたい(PresentBoxAnimationをさっさとマウントしたい)ため、keepMountedとしている */}
      <Dialog keepMounted disableBackdropClick disableEscapeKeyDown open={showAnimationModal} onEntered={onEnteredAnimationModal} onClose={onCloseAnimationModal} scroll="paper" fullWidth={true}>
        <PresentBoxAnimationDialogContent>
          <PresentBoxAnimationStyled playAnimation={playAnimation} />
          <EnterButton onClick={onCloseAnimationModal} size="large" variant="contained" color="primary">
            抽選に進む
          </EnterButton>
        </PresentBoxAnimationDialogContent>
      </Dialog>
    </>
  );
};

export default CampaignTop;
