import React, { ReactElement } from "react";
import styled from "styled-components";
import config from "../config";
import ExternalLink from "../components/atoms/ExternalLink";
import { Message, SubMessage } from "../components/atoms/Messages";
import ResponsiveImage from "../components/atoms/ResponsiveImage";

const Root = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
`;

const Logo = styled(ResponsiveImage)`
  margin: 72px auto;
  max-width: 166px;
  width: 40%;
`;

const Motan = styled(ResponsiveImage)`
  margin: 32px auto 0;
  max-width: 105px;
  width: 30%;
`;

interface Props {
  className?: string;
}
const ErrorPage = ({ className }: Props): ReactElement => {
  return (
    <Root className={className}>
      <Logo rate={(173 / 333) * 100} src={process.env.PUBLIC_URL + "/logo-333x173.png"} />
      <Message>エラーが発生しました</Message>
      <SubMessage>
        時間をあけて再度お試し下さい
        <br />
        キャンペーンに関する最新の情報は
        <br />
        <ExternalLink href={config.officialTwitterUrl}>テクテクライフ公式Twitter</ExternalLink>をご確認下さい
      </SubMessage>
      <Motan rate={(249 / 210) * 100} src={process.env.PUBLIC_URL + "/motan-210x249.png"} />
    </Root>
  );
};

export default ErrorPage;
