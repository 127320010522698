import React from "react";
import styled from "styled-components";

const Root = styled.div<{ src: string; playAnimation: boolean }>`
  width: 100%;
  max-width: 290px;
  max-height: 290px;
  background-image: url(${(props) => props.src});
  background-size: 100%;
  animation-fill-mode: forwards;
  ${(props) => (props.playAnimation ? "animation: moveY 3s steps(35)" : "background-position-y: calc(100% * 35 * -1)")};
  &:before {
    content: "";
    padding-top: 100%;
    display: block;
  }
  @keyframes moveY {
    from {
      // レスポンシブなので、widthの動的な大きさ*アニメーションステップ数分先の位置までアニメーションする
      // 横幅が290pxならば-10150pxだし、280pxならば-9800pxまでアニメーションする
      background-position-y: calc(100% * 35 * -1);
    }
    to {
      background-position-y: 0px;
    }
  }
`;
enum ImageLoadState {
  NotLoad,
  Loading,
  Loaded
}
interface Props {
  className?: string;
  playAnimation: boolean;
}
const ANIMATION_PNG = process.env.PUBLIC_URL + "/anime-presentbox-sprite.png";

const PresentBoxAnimation = ({ className, playAnimation }: Props) => {
  const [imageLoadedState, setImageLoadedState] = React.useState<ImageLoadState>(ImageLoadState.NotLoad);
  React.useEffect(() => {
    if (imageLoadedState === ImageLoadState.NotLoad) {
      const imgTag = new Image();
      imgTag.onload = () => {
        setImageLoadedState(ImageLoadState.Loaded);
      };
      imgTag.src = ANIMATION_PNG;
      setImageLoadedState(ImageLoadState.Loading);
    }
  }, []);
  return <Root playAnimation={imageLoadedState === ImageLoadState.Loaded && playAnimation} src={ANIMATION_PNG} className={className} />;
};

export default PresentBoxAnimation;
