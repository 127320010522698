import { CampaignInfo, LotteryResult } from "../app-types";
import { FirebaseService } from "../services";

export async function getCampaignInfoByToken(token: string, campaignName: string): Promise<{ result: CampaignInfo; error?: string }> {
  const service = FirebaseService.createService();
  return await service.getCampaignResult(token, campaignName);
}

export async function postLotteryCampaignByToken(token: string, campaignName: string): Promise<{ result: LotteryResult; error?: string }> {
  const service = FirebaseService.createService();
  return await service.lotteryCampaign(token, campaignName);
}
