import { FirebaseService } from "../services";

export function logScreenView(screenName: "top" | "win" | "lose") {
  const service = FirebaseService.createService();
  service.logScreenView(screenName);
}

export function logDoLotteryEvent(playerId: string, campaignId: string, lotteryId: string, prizeId: string | null, lotteryResult: "win" | "lose") {
  const service = FirebaseService.createService();
  service.logDoLotteryEvent(playerId, campaignId, lotteryId, prizeId, lotteryResult);
}
