import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import { ThemeProvider } from "@material-ui/core";
// マテリアルUIのモーダル(Dialog)で Warning: findDOMNode is deprecated in StrictMode が出る対策として、unstable_createMuiStrictModeThemeを使用
// マテリアルUIのバージョンが進み、解決されたらcreateMuiThemeに置き換える
// https://github.com/mui-org/material-ui/issues/13394
import { unstable_createMuiStrictModeTheme as createMuiTheme, StylesProvider } from "@material-ui/core/styles";
import * as modules from "./services/modules";
import { withDevProtect } from "./components/withDevProtect";
import Footer from "./components/organisms/Footer";
import ErrorPage from "./pages/ErrorPage";
import Campaign from "./pages/Campaign";
import { breakpoints, color, contentWidth, fontFamily, fontSize } from "./components/styles/common";

modules.FirebaseModule.init();

const RootStyles = createGlobalStyle`
  html {
    height: 100%;
    margin: 0;
    font-size: ${fontSize.base}px;
    background: ${color.white};
  }
  body {
    height: 100%;
    ${fontSize.medium};
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: ${fontFamily};
  }
  a {
    text-decoration: none;
  }
  #root {
    // Inherit to child-content
    height: 100%;
  }
`;

const Root = styled.div`
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%;
  letter-spacing: 0.035rem;
`;
const Main = styled.main`
  max-width: ${contentWidth}px;
  margin: 0 auto;
  width: 100%;
  flex: 1 1 auto;
`;
const FooterStyled = styled(Footer)`
  align-self: flex-end;
`;

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.blue,
      dark: color.darkBlue,
      light: color.lightBlue,
      contrastText: color.white
    },
    secondary: {
      main: color.orange,
      dark: color.darkOrange,
      light: color.darkOrange,
      contrastText: color.white
    },
    text: {
      primary: color.textPrimary,
      secondary: color.textSecondary
    }
  },
  breakpoints: {
    values: breakpoints
  },
  typography: {
    fontSize: fontSize.base,
    fontFamily: fontFamily
  }
});
function App() {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <HelmetProvider>
          <RootStyles />
          <Helmet>
            <title>テクテクライフ キャンペーンサイト</title>
            <meta name="description" content="テクテクライフ キャンペーンサイトです" />
          </Helmet>
          <Root>
            <Main>
              <Router>
                <Switch>
                  <Route exact path="/:campaignName">
                    <Campaign />
                  </Route>
                  <Route path="*">
                    <ErrorPage />
                  </Route>
                </Switch>
              </Router>
            </Main>
            <FooterStyled />
          </Root>
        </HelmetProvider>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default withDevProtect(App);
