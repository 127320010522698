import React, { ReactElement } from "react";
import { Button, ButtonProps } from "@material-ui/core";

const ExternalLinkButton = ({ children, ...props }: ButtonProps<"a">): ReactElement => {
  return (
    <Button component="a" {...props} rel="noreferrer" target="_blank">
      {children}
    </Button>
  );
};

export default ExternalLinkButton;
