// functions側と共通の記述
export enum FunctionError {
  NotFoundCampaignMasterData = "NotFoundCampaignMasterData",
  NotFoundCampaignWinningMasterData = "NotFoundCampaignWinningMasterData",
  NotFoundCampaignPrizeMasterData = "NotFoundCampaignPrizeMasterData",
  DuplicateCampaignAcceleratePrizeMasterData = "DuplicateCampaignAcceleratePrizeMasterData",
  DischargedCampaignWinningMasterData = "DischargedCampaignWinningMasterData",
  ForcedTerminationCampaign = "ForcedTerminationCampaign",
  InvalidCampaign = "InvalidCampaign",
  InvalidToken = "InvalidCampaign",
  InvalidLotteryId = "InvalidLotteryId",
  InvalidExpiration = "InvalidExpiration",
  CampaignServerInternalError = "CampaignServerInternalError"
}

// GAイベント
export const GA_EVENT_NAME_TTL_DO_LOTTERY = "ttl_do_lottery";
export const GA_EVENT_PARAMS_PLAYER_ID = "player_id";
export const GA_EVENT_PARAMS_CAMPAIGN_ID = "campaign_id";
export const GA_EVENT_PARAMS_LOTTERY_ID = "lottery_id";
export const GA_EVENT_PARAMS_PRIZE_ID = "prize_id";
export const GA_EVENT_PARAMS_LOTTERY_RESULT = "lottery_result";
