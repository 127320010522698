import { CampaignInfo, LotteryResult } from "../app-types";
import { CAMPAIGN_NONE, CAMPAIGN_WINNING, CAMPAIGN_LOSE } from "./fixtures/campaign";

export async function getCampaignInfoByToken(token: string, campaignId: string): Promise<{ result: CampaignInfo; error?: string }> {
  console.log("GET /campaignInfo へのmockModeリクエスト", token, campaignId);
  await new Promise((resolve) => setTimeout(resolve, 1500));
  // token=win でアタリ済み
  // token=lose でハズレ済み
  // token=null-win で抽選後アタリ
  // token=null-lose で抽選後ハズレ
  if (/^win.*/.test(token)) {
    return { result: CAMPAIGN_WINNING };
  } else if (/^lose.*/.test(token)) {
    return { result: CAMPAIGN_LOSE };
  }
  return { result: CAMPAIGN_NONE };
}

export async function postLotteryCampaignByToken(token: string, campaignId: string): Promise<{ result: LotteryResult; error?: string }> {
  console.log("POST /lotteryCampaign へのmockModeリクエスト", token, campaignId);
  await new Promise((resolve) => setTimeout(resolve, 500));
  // token=null-win で抽選後アタリ
  // token=null-lose で抽選後ハズレ
  if (/^null-win.*/.test(token)) {
    return { result: CAMPAIGN_WINNING };
  }
  return { result: CAMPAIGN_LOSE };
}
