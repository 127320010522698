import React from "react";
import styled from "styled-components";
import { LotteryResult } from "../../app-types";
import { formatUnixTimeToJaDateString } from "../../utils/date";
import { color, fontSize } from "../styles/common";
import { SubMessage, Duration } from "../atoms/Messages";

const Root = styled.div`
  background-color: ${color.giftListBgGreen};
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
`;
const SubMessageStyled = styled(SubMessage)`
  margin: 16px auto 0;
  padding: 0 16px;
  & > span {
    ${fontSize.xsmall};
  }
`;
const DurationStyled = styled(Duration)`
  margin: 16px auto 0;
`;

const ImageWrapper = styled.div<{ showOne: boolean }>`
  width: ${({ showOne }) => (showOne ? "100%" : "calc(50% - 4px)")}};
  flex: 0 1 auto;
  margin-bottom: 8px;
  position: relative;
  background-color: ${color.gray};
  &:before {
    content: "";
    display: block;
    padding-top: 75%;
  }
  &:nth-of-type(2n - 1) {
    width: ${({ showOne }) => (showOne ? "100%" : "calc(50% - 4px)")}};
    flex: 0 1 auto;
    margin-right: ${({ showOne }) => (showOne ? "0px" : "8px;")}};
  }
`;
const Image = styled((props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => {
  return <img {...props}></img>;
})`
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;
const ImageList = styled(({ className, images }: { className?: string; images: string[] }) => {
  // FIXME: 仕様変更でランダムに一個表示することとなった
  // 「※画面を再読み込みすると、別の画像をダウンロードできます。」の文言とともにマスターデータにより運用可能にしたほうがよい
  if (images.length === 0) {
    return null;
  }
  const randomImages = [images[Math.floor(Math.random() * images.length)]];
  return (
    <div className={className}>
      {randomImages.map((image, i) => {
        return (
          <ImageWrapper showOne={randomImages.length < 2} key={`gift-image-wrapper-${i}`}>
            <Image key={`gift-image-${i}`} src={image} />
          </ImageWrapper>
        );
      })}
    </div>
  );
})`
  padding: 0 12px;
  width: calc(100% - 24px);
  display: flex;
  flex-flow: row wrap;
  margin: 16px auto;
`;

interface Props {
  className?: string;
  gifts: LotteryResult["lottery"]["gifts"];
  duration: {
    start: LotteryResult["start"];
    end: LotteryResult["end"];
  };
}

const GiftList = ({ className, gifts, duration }: Props) => {
  return (
    <Root className={className}>
      <SubMessageStyled>
        以下より景品がダウンロードできます。
        <br />
        保存してお使い下さい。
        <br />
        <span>※画像を長押ししてダウンロードしてください。</span>
        <br />
        <span>※画面を再読み込みすると別の画像をご覧になれます。</span>
      </SubMessageStyled>
      <DurationStyled term="ダウンロード可能期間">
        {formatUnixTimeToJaDateString(duration.start)}〜{formatUnixTimeToJaDateString(duration.end)}
      </DurationStyled>
      <ImageList images={gifts.filter((gift) => gift.type === "image").map((gift) => gift.content)} />
    </Root>
  );
};

export default GiftList;
