import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/functions";
import config from "../../config";

export module FirebaseModule {
  export function init(): void {
    firebase.initializeApp(config.firebaseConfig);
    firebase.analytics();
  }
  export function getFunctionsClient(): firebase.functions.Functions {
    const functions = firebase.app().functions(config.functionsRegion);
    if (config.functionsEmulator !== null) {
      functions.useEmulator(config.functionsEmulator.host, config.functionsEmulator.port);
    }
    return functions;
  }
  export function getAnalyticsClient(): firebase.analytics.Analytics {
    return firebase.analytics();
  }
}
