import React from "react";
import styled from "styled-components";
import { color, fontSize } from "../styles/common";
import { formatUnixTimeToJaDateTimeString } from "../../utils/date";

const Root = styled.div`
  ${fontSize.xsmall};
  margin: 16px;
  color: ${color.textSecondary};
  border: 1px solid ${color.lightGray};
  border-radius: 4px;
  padding: 16px;
  width: calc(100% - 64px);
`;
interface Props {
  className?: string;
  campaignTitle: string;
  lotteryId?: string;
  lotteryAt?: number;
}

const CampaignMetadata = ({ className, campaignTitle, lotteryId, lotteryAt }: Props) => {
  return (
    <Root className={className}>
      キャンペーン名: {campaignTitle}
      <br />
      抽選ID: {lotteryId || "-"}
      <br />
      抽選日時: {lotteryAt ? formatUnixTimeToJaDateTimeString(lotteryAt) : "-"}
    </Root>
  );
};

export default CampaignMetadata;
