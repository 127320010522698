import React from "react";
import styled from "styled-components";
import { LotteryResult } from "../../app-types";
import { formatUnixTimeToJaDateString } from "../../utils/date";
import { color, fontSize } from "../styles/common";
import ExternalLinkButton from "../atoms/ExternalLinkButton";
import { Message, SubMessage, Duration } from "../atoms/Messages";
import ResponsiveImage from "../atoms/ResponsiveImage";
import CampaignMetadata from "../molecules/CampaignMetadata";
import GiftList from "../molecules/GiftList";

const MessageStyled = styled(Message)`
  margin: 16px auto 0;
  padding: 0 16px;
`;
const SubMessageStyled = styled(SubMessage)`
  margin: 16px auto 0;
  padding: 0 16px;
`;
const Note = styled.div`
  ${fontSize.xsmall};
  color: ${color.textSecondary};
  text-align: center;
  margin: 16px auto 0;
  padding: 0 16px;
`;
const DurationTextStyled = styled(Duration)`
  margin: 16px auto 0;
`;
const EntryButton = styled(ExternalLinkButton)`
  margin: 8px auto 0;
  min-width: 85%;
  font-weight: bold;
  font-size: 1.2rem;
`;
const GiftListStyled = styled(GiftList)`
  margin-top: 32px;
`;

interface Props {
  lotteryResult: LotteryResult;
}
const LotteryResultPage = ({ lotteryResult }: Props) => {
  return (
    <>
      {lotteryResult.lottery.winning === null ? (
        <>
          <ResponsiveImage rate={(170 / 360) * 100} src={process.env.PUBLIC_URL + "/lottery-result-blank-720x340.png"} />
          <MessageStyled>
            残念ながらハズレです。
            <br />
            またの挑戦をお待ちしております。
          </MessageStyled>
          {lotteryResult.lottery.gifts.length > 0 ? <GiftListStyled gifts={lotteryResult.lottery.gifts} duration={{ start: lotteryResult.start, end: lotteryResult.end }} /> : null}
          <CampaignMetadata campaignTitle={lotteryResult.title} lotteryId={lotteryResult.lottery.id} lotteryAt={lotteryResult.lottery.lotteryAt} />
        </>
      ) : (
        <>
          <ResponsiveImage rate={(170 / 360) * 100} src={process.env.PUBLIC_URL + "/lottery-result-winning-720x340.png"} />
          <ResponsiveImage rate={(270 / 360) * 100} src={lotteryResult.lottery.winning.imageUrl} />
          <MessageStyled>
            当選しました！
            <br />
            おめでとうございます。
          </MessageStyled>
          <SubMessageStyled>
            下のボタンから景品のお届け先情報の
            <br />
            ご登録をお願いします。
          </SubMessageStyled>
          <Note>
            ※お届け先情報の登録は後からでも行うことができます
            <br />
            ※お届け先情報の登録ができるのは一度のみとなります
            {/* スパイラル側のメンテナンス日時を一時的に表示している。期間が過ぎたら消していい */}
            {Date.now() < new Date("2022-01-26T08:00:00+09:00").getTime() ? (
              <React.Fragment>
                <br />
                <br />
                ※以下の期間は、システムメンテナンスのため登録ができなくなります。
                <br />
                ご不便をおかけしますが、ご理解のほどよろしくお願いいたします。
                <br />
                2022年1月25日（火）23:00 - 26日（水）8:00
              </React.Fragment>
            ) : null}
          </Note>
          <DurationTextStyled term="登録可能期間">
            {formatUnixTimeToJaDateString(lotteryResult.start)}〜{formatUnixTimeToJaDateString(lotteryResult.end)}
          </DurationTextStyled>
          <EntryButton size="large" variant="contained" color="primary" href={lotteryResult.lottery.winning.formUrl}>
            お届け先情報を登録する
          </EntryButton>
          {lotteryResult.lottery.gifts.length > 0 ? <GiftListStyled gifts={lotteryResult.lottery.gifts} duration={{ start: lotteryResult.start, end: lotteryResult.end }} /> : null}
          <CampaignMetadata campaignTitle={lotteryResult.title} lotteryId={lotteryResult.lottery.id} lotteryAt={lotteryResult.lottery.lotteryAt} />
        </>
      )}
    </>
  );
};

export default LotteryResultPage;
